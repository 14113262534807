<template>
    <Layout>
        <div class="errorPage">
            <div class="notFoundError">
                <div class="notFoundError__contentWrapper">
                    <img alt="ready" src="@/assets/img/pages/utility/ready.png" />
                    <div class="notFoundError__frame">
                        <p class="text4 colorGray notFoundError__frameText">
                            У любого трейдера может пойти что-то не так. Главное - не
                            отчаиваться, найти способ вернуться на шаг назад и двигаться
                            дальше
                        </p>
                        <img
                            class="notFoundError__img"
                            alt="arrow"
                            src="@/assets/img/pages/utility/arrow.png"
                        />
                    </div>
                </div>
                <div class="notFoundError__contentWrapper">
                    <div class="notFoundError__buttonBox">
                        <p class="h1">Ошибка! Что-то пошло не так</p>
                        <Button
                            class="notFoundError__buttonBox_button"
                            title="на главную"
                            :isLink="true"
                            :link="'/'"
                        />
                    </div>
                    <p class="colorZircon notFoundError__text">404</p>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import Button from "@/views/components/Button/Button";
export default {
    name: "404",

    components: {
        Layout,
        Button,
    },
};
</script>

<style lang="scss" scoped>
@import "404.scss";
</style>
